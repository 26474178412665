import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Select, { components } from 'react-select'

import { GET, POST } from 'helpers/api'
import { formatMoney, checkRole } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation();
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)

	const canSave = checkRole('MAIN_USERS_ADD')

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [isOpen, setIsOpen] = useState(false)
	const [poses, setPoses] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [settings, setSettings] = useState({
		'size': 20,
		'decimalPoint': 2,
		'ofd': false,
		'currencyId': "",
		'posId': "",
		'organizationId': "",
		'rememberLastDocumentInPreview': null,
		'tableNomenclature': {
			'posName': true,
			'organizationName': true,
			'wholesalePrice': true,
			'currencyName': true,
			'serial': true,
			'expDate': true,
			'balance': true,
		},
		'tableDocumentsInCreate': {
			'uomName': true,
			'wholesalePrice': true,
			'serial': true,
			'expDate': true,
			'vat': true,
		},
		'tableDocumentsInPreview': {
			'serial': true,
			'expDate': true,
			'wholesalePrice': true,
			'vat': true,
		},
		'tableReportIncomes': {
			'wholesalePrice': true,
			'currencyName': true,
			'vat': true,
			'vatAmount': true,
		},
		'tableReportSales': {
			'cashier': true,
			'currencyName': true,
			'discount': true,
		}
	})

	async function saveSettings() {
		var sendData = {
			'settings': JSON.stringify(settings)
		}
		const response = await POST("/services/web/api/user-settings", sendData, { loader: true })
		if (response) {
			dispatch({ type: 'SET_SETTINGS', payload: settings })
			setSettings(settings)
		}
	}

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		setSettings({ ...settings, 'categoryId': option?.id ? option.id : '' })
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getOrganizations() {
		const response = await GET('/services/web/api/organization-helper')
		if (response) {
			setOrganizations(response)
		}
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	useEffect(() => {
		getCategories()
		getPoses()
		getOrganizations()
		setSettings(reduxSettings)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: settings.categoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: settings.categoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	return (
		<>
			<div className="card mb-3">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="vertical-center">
							<h5 className="m-0 fw-500">{t('CASHBOX')}(x64)</h5>
						</div>
						<div className="vertical-center">
							<a href={process.env.REACT_APP_PROD_SERVER_URL + "/download/mDokon.exe"} className="btn btn-primary">
								{t('DOWNLOAD')}
							</a>
						</div>
					</div>

					<div className="d-flex justify-content-between">
						<div className="vertical-center">
							<h5 className="m-0 fw-500">{t('CASHBOX')}(x32)</h5>
						</div>
						<div className="vertical-center">
							<a href={process.env.REACT_APP_PROD_SERVER_URL + "/download/mDokon32.exe"} className="btn btn-primary">
								{t('DOWNLOAD')}
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h5 className="settings_title">{t('BY_DEFAULT')}</h5>

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="vertical-center">
							<h6 className="m-0 fw-600">{t('CURRENCY')}</h6>
							<span className="fz14 ms-3">Валюта по умолчанию (прием товаров)</span>
						</div>
						<div className="vertical-center w-250px">
							<Select
								options={currencies}
								value={currencies.find(option => option.id === settings.currencyId)}
								onChange={(option) => setSettings({ ...settings, 'currencyId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="vertical-center">
							<h6 className="m-0 fw-600">{t('POS')}</h6>
							<span className="fz14 ms-3">Торговая точка по умолчанию (прием товаров, инвентаризация)</span>
						</div>
						<div className="vertical-center w-250px">
							<Select
								options={poses}
								value={poses.find(option => option.id === settings.posId)}
								onChange={(option) => setSettings({ ...settings, 'posId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="vertical-center">
							<h6 className="m-0 fw-600">{t('SUPPLIER')}</h6>
							<span className="fz14 ms-3">Поставщик по умолчанию (прием товаров)</span>
						</div>
						<div className="vertical-center w-250px">
							<Select
								options={organizations}
								value={organizations.find(option => option.id === settings.organizationId)}
								onChange={(option) => setSettings({ ...settings, 'organizationId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>

					<div className="d-flex justify-content-between mb-3">
						<div className="vertical-center">
							<h6 className="m-0 fw-600">{t('CATEGORY')}</h6>
							<span className="fz14 ms-3">Категория по умолчанию(продукты)</span>
						</div>
						<div className="vertical-center w-250px">
							<Select
								isClearable
								components={{ GroupHeading: GroupHead, Option }}
								classNamePrefix="react-select"
								options={categories}
								value={categoriesInitial.find(option => option.id === settings.categoryId || '')}
								onChange={(option) => selectCategory(option)}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								onMenuOpen={() => setIsOpen(true)}
								onBlur={() => setIsOpen(false)}
								menuIsOpen={isOpen}
							/>
						</div>
					</div>

					<div className="d-flex justify-content-between mb-3">
						<div className="vertical-center">
							<h6 className="m-0 fw-600">{t('NUMBER_AFTER_DECIMAL_POINT')}</h6>
							<span className="fz14 ms-3">Сколько чисел показывать после запятой (целые числа)</span>
						</div>
						<div className="vertical-center w-250px">
							<div className="form-group d-flex">
								<input type="range" className="form-control-range w-100" max="3" min="0" value={settings.decimalPoint}
									onChange={(e) => setSettings({ ...settings, 'decimalPoint': e.target.value })} />
							</div>
							<div className="d-flex justify-content-between">
								<div>{t('EXAMPLE')}:</div>
								<div>{formatMoney(560.416, settings.decimalPoint)} {t('SUM')}</div>
							</div>
						</div>
					</div>

					<div className="d-flex justify-content-between mb-5 d-none">
						<div className="vertical-center">
							<h6 className="m-0 fw-600">{t('OFD')}</h6>
							<span className="fz14 ms-3"></span>
						</div>
						<div className="vertical-center w-250px">
							<div className="form-check form-switch form-switch-lg d-flex justify-content-end">
								<input type="checkbox" className="form-check-input" name="saleMinus" checked={settings.ofd}
									value={settings.ofd} onChange={(e) => setSettings({ ...settings, ofd: e.target.checked })}
								/>
							</div>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-5">
						{canSave &&
							<button className="btn btn-outline-primary btn-wide" onClick={saveSettings}>
								{t('SAVE')}
							</button>
						}
					</div>

				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h5 className="settings_title">{t('POS_SETTINGS')}</h5>

					<div className="row">
						<div className="col-md-6">
							<div className="d-flex justify-content-between mb-2">
								<p className="fw-600 vertical-center">{t('DARK_THEME')}</p>
								<div className="form-check form-switch form-switch-lg">
									<input type="checkbox" className="form-check-input" name="theme"
										checked={settings.theme}
										onChange={(e) => setSettings({ ...settings, 'theme': e.target.checked })} />
								</div>
							</div>
						</div>

						<div className="col-md-6"></div>

						<div className="col-md-6">
							<div className="d-flex justify-content-between mb-2">
								<p className="fw-600 vertical-center">{t('RECEPTION_OF_GOODS_CALCULATION_BY_AMOUNT')}</p>
								<div className="form-check form-switch form-switch-lg">
									<input type="checkbox" className="form-check-input" name="documentsInCalculationByAmount"
										checked={settings.documentsInCalculationByAmount}
										onChange={(e) => setSettings({ ...settings, 'documentsInCalculationByAmount': e.target.checked })} />
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-5">
						{canSave &&
							<button className="btn btn-outline-primary btn-wide" onClick={saveSettings}>
								{t('SAVE')}
							</button>
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default Index
